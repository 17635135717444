import { COOKIE } from '@iva/utils';
import { marker, markerForceCookie } from '@iva/marker';
import { COOKIE_NAMES } from './constants';

/**
 * List of rtgIds where udid should not be generated
 */
export const BLACKLIST = [1333333];

/**
 * Handles udid generation
 * @param {number} rtgId retargeting ID
 * @param {number} consent user consent
 */
export const handleUdid = (rtgId, consent) => {
  if (BLACKLIST.includes(rtgId)) {
    if (!COOKIE.getCookie(COOKIE_NAMES.UDID)) {
      window.sznIVA.IS.clearIdentities(['udid']);
    }
    return;
  }

  if (consent === 1) {
    // already has consent from config, force creating mark
    markerForceCookie();
    return;
  }

  if (consent !== 0) {
    // waits for consent and creates mark (when consent exists later)
    marker();
  }
};
