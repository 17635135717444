/**
 *
 * @param {String} rtgUrl URL partner wants to use.
 * @return {String} Valid URL to use.
 */
const getBaseUrl = (rtgUrl) => {
  const locUrl = window.location.href;

  if (!rtgUrl) {
    return locUrl;
  }

  const locDelimiter = ~locUrl.indexOf('?') ? '?' : '#';
  const rtgDelimiter = ~rtgUrl.indexOf('?') ? '?' : '#';
  const [baseLoc] = locUrl.split(locDelimiter);
  const [baseRtg] = rtgUrl.split(rtgDelimiter);

  if (baseLoc === baseRtg) {
    return rtgUrl;
  }
  return locUrl;
};

/**
 *
 * @param {String} rtgUrl URL partner wants to use.
 * @param {String} category Current category.
 * @param {boolean} internal Is this internal web?
 * @return {String}
 */
export const getRetargetingUrl = (rtgUrl, category, internal) => {
  const baseURL = getBaseUrl(rtgUrl);
  if (!internal || !category) {
    return baseURL;
  }
  const delimeter = baseURL.indexOf('?') === -1 ? '?' : '&';
  return baseURL + delimeter + 'category=' + category;
};
