// IVA libraries
import '@iva/refresher';
import { handleSbrowserSid } from '@iva/sbrowser-sid';
import { startErrorHandling } from '@iva/error-reporter';

// Modules
import { runLinkdecoration } from './modules/linkdecoration';
import { rcDebug as debug } from './modules/debug.js';
import { initConsentLogicInWindow } from './modules/consentManager';
import { retargetingHit, handleRetargetingHits } from './modules/retargetingHit';
import { conversionHit, conversionHitSklik } from './modules/conversionHit';
import { conversionHitZbozi } from './modules/zbozi';
import { getConversionDataSklik, getConversionDataZbozi } from './modules/utils/getConversionData';
import { getRandomString } from './modules/utils/getRandomString';

// Constants
import { DEBUGGER_STATES, CONVERSION_HIT_ID_LENGTH } from './modules/constants.js';

startErrorHandling({
  endpoint: 'sklik-ap-static',
  scriptName: 'rc.js',
});

debug({ message: 'rc.js started', state: DEBUGGER_STATES.START });

window.rc = window.rc || {};
window.rc.retargetingHit = retargetingHit;
window.rc.conversionHit = conversionHit;
delete window.rc.isStub;
initConsentLogicInWindow(debug);
debug({ message: 'Window object inited' });

if (!handleSbrowserSid()) {
  runLinkdecoration(null, 30000);
}

// retargeting hits
handleRetargetingHits();

// conversionHitId {String[75]} Unique conversion ID for sklik and zbozi
const conversionHitId = getRandomString(CONVERSION_HIT_ID_LENGTH);

// SKLIK conversion
const conversionConfSklik = getConversionDataSklik();
if (conversionConfSklik) {
  debug({ message: 'Conversion got this data (sklik-conversion):', ...conversionConfSklik });
  if (Array.isArray(conversionConfSklik)) {
    conversionConfSklik.forEach((conversionConfSklikFromArray) => {
      conversionHitSklik({ ...conversionConfSklikFromArray, conversionHitId }, debug);
    });
  } else {
    conversionHitSklik({ ...conversionConfSklik, conversionHitId }, debug);
  }
}

// ZBOZI conversion
const conversionConfZbozi = getConversionDataZbozi();
debug({ message: 'Conversion got this data (zbozi-conversion):', ...conversionConfZbozi });
conversionHitZbozi({ ...conversionConfZbozi, conversionHitId }, debug);

debug({ message: 'rc.js finished', state: DEBUGGER_STATES.DONE });
