import 'custom-event-polyfill';

const EVENT_SET_CONSENT = 'szn:rc:setconsentvalue';

// waiting for consent (cmp2-common lib)
let waitingForLegalStoreCheck = true;

/**
 * Finish waiting for consent
 */
const finishWaitingForLegalStoreCheck = () => {
  waitingForLegalStoreCheck = false;
};

/**
 * Checks if still waiting for consent
 * @returns {boolean}
 */
const isWaitingForLegalStoreCheck = () => waitingForLegalStoreCheck;

/**
 * After setting window.rc.consent value to 0 or 1, creates an event
 * This event is needed for linkdecoration
 * @param {Number} consentValue
 */
const dispatchSetConsentEvent = (consentValue) => {
  window.dispatchEvent(
    new CustomEvent(EVENT_SET_CONSENT, {
      detail: { consent: consentValue },
    })
  );
};

/**
 * Сhecks valid consent-value or not
 * @returns {Boolean} true if value is valid
 */
const isValueValidForConsent = (consentValue) => [0, 1, '0', '1'].includes(consentValue);

/**
 * Returns verified consent value.
 * @returns {-1 | 0 | 1} true if value is valid
 */
const getVerifiedConsentValue = (consentValue) =>
  isValueValidForConsent(consentValue) ? parseInt(consentValue, 10) : -1;

/**
 * Get consent from window.rc
 * use consent-getter
 * @return {Number | Null} Consent value
 */
const getConsent = () => window.rc && window.rc.consent;

/**
 * Set consent to window.rc
 * use consent-setter
 * @param {Number} consentValue
 */
const setConsent = (consentValue) => {
  if (window.rc && !window.rc.internal) {
    window.rc.consent = consentValue;
  }
};

/**
 * Adds to window.rc consent logic (setter and getter)
 * param {Function} debug Debugger function from the script calling this function.
 */
const initConsentLogicInWindow = (debug = () => {}) => {
  let consentBeforeInit = null;
  let isConsentBeforeInitLogic = false;
  if (window.rc && window.rc.hasOwnProperty('consent') && !Object.getOwnPropertyDescriptor(window.rc, 'consent').get) {
    // consent-getter and consent-setter haven't been declared yet, but consent-value already exists
    // we memorize this consent-value and then assign it using a setter
    isConsentBeforeInitLogic = true;
    consentBeforeInit = window.rc.consent;
  }

  window.rc = {
    ...window.rc,

    // consent-setter
    set consent(consentValue) {
      // if not internal and valid set consent
      if (!window.rc.internal && isValueValidForConsent(consentValue)) {
        this._consent = parseInt(consentValue, 10);
        debug(`Set window.rc.consent: ${consentValue}`);
        dispatchSetConsentEvent(consentValue);
        return;
      }
      debug('error', 'Invalid consent value. Аllowed values: 0 or 1.');
      this._consent = null;
    },

    // consent-getter
    get consent() {
      if (isValueValidForConsent(this._consent)) {
        return this._consent;
      }
      return null;
    },
  };

  if (isConsentBeforeInitLogic) {
    window.rc.consent = consentBeforeInit;
    isConsentBeforeInitLogic = false;
  }
};

export {
  isValueValidForConsent,
  getVerifiedConsentValue,
  getConsent,
  setConsent,
  initConsentLogicInWindow,
  isWaitingForLegalStoreCheck,
  finishWaitingForLegalStoreCheck,
};
