/**
 * Generates a pseudo-random string of specified length
 * @param {Number} length string length
 * @returns {String}
 */
export const getRandomString = (length = 50) => {
  const CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  const cryptoObj = window.crypto || window.msCrypto;
  if ('Uint8Array' in self && cryptoObj && length <= 65536) {
    const uint8array = new Uint8Array(length);
    cryptoObj.getRandomValues(uint8array);
    for (let i = 0; i < length; i++) {
      result += CHARS[uint8array[i] % CHARS.length];
    }
  } else {
    for (let i = 0; i < length; i++) {
      result += CHARS.charAt(Math.floor(Math.random() * CHARS.length));
    }
  }
  return result;
};
