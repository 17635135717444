import { conversionHitZbozi } from './zbozi';

import { CONVERSION_BASE_URL, COOKIE_NAMES, CONVERSION_HIT_ID_LENGTH } from './constants';
import { setConsent, getVerifiedConsentValue } from './consentManager';
import { getRandomString } from './utils/getRandomString';

import { getIdentityObject } from './utils/getIdentityObject';

import { HitSender } from './HitSender/HitSender';

import { SID_UTILS, COOKIE } from '@iva/utils';

// conversion Hit Sklik
export const conversionHitSklik = (conf, debug) => {
  if (!conf?.id || !conf?.conversionHitId) {
    debug(
      'error',
      'ConversionHit has not been sent to sklik server. No required fields "conf.id" or "conf.conversionHitId".'
    );
    return;
  }
  const { id, value, orderId, consent, conversionHitId } = conf;

  const verifiedConsent = getVerifiedConsentValue(consent);
  const { internal } = window.rc || {};
  const consentValue = internal ? null : verifiedConsent;
  const legallyOk = verifiedConsent || internal;

  const sidCookie = COOKIE.getCookie(COOKIE_NAMES.SID);
  const sid = legallyOk && SID_UTILS.isValidSid(sidCookie) ? sidCookie : '';

  const sznaiidCookie = COOKIE.getCookie(COOKIE_NAMES.SZNAIID);
  const sznaiid = legallyOk ? sznaiidCookie : '';

  const euconsentV2 = internal && COOKIE.getCookie(COOKIE_NAMES.EUCONSENT);

  const ids = getIdentityObject();

  debug({ message: 'got following conversion data:', id, value, orderId, sid, euconsentV2 });

  new HitSender(CONVERSION_BASE_URL, debug)
    .appendValue('id', id)
    .appendValue('value', value)
    .appendValue('orderId', orderId)
    .appendValue('url', window.location.href)
    .appendValue('dsid', sid)
    .appendValue('sznaiid', sznaiid)
    .appendValue('euconsent', euconsentV2)
    .appendValue('consent', consentValue)
    .appendValue('conversionHitId', conversionHitId)
    .appendObject('ids', ids)
    .send();
};

/**
 * Sends conversion hit with provided data.
 * @param {Object} conf Data for the conversion.
 * @param {Number} [conf.id] Conversion ID, Required field for SKLIK, Example = 123
 * @param {Number} [conf.value] Order price, Example = 199
 * @param {String} [conf.orderId] OrderId, Example = 'DN1231882C'
 * @param {String} [conf.zboziType] Type of zbozi message,
 * Example = 'standard' (standard conversion tracking),
 *           'limited' (limited tracking),
 *           'sandbox' (standard tracking test mode)
 * @param {Number} [conf.zboziId] Eshop ID, Required field for ZBOZI, Example = 123
 * @param {Number} [conf.consent] Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
 *
 * @param {Function} debug Debugger function from the script calling this function.
 */
export const conversionHit = (conf, debug = () => {}) => {
  if (conf) {
    setConsent(conf.consent);
  }
  // conversionHitId {String[75]} Unique conversion ID for sklik and zbozi
  conf.conversionHitId = getRandomString(CONVERSION_HIT_ID_LENGTH);
  conversionHitSklik(conf, debug);
  conversionHitZbozi(conf, debug);
};
