/** Class for compiling and sending hits. */
export class HitSender {
  #target = '';
  #isFirst = true;
  #debug = () => {};
  #maxLength = 2048;

  /**
   * Instantiate HitSender.
   * @param {String} baseURL Target URL to which further data may be appended.
   * @param {Function} debug Optional - instance of debugger or other debugging function.
   */
  constructor(baseURL, debug) {
    this.#target = baseURL;
    if (debug) {
      this.#debug = debug;
    }
  }

  #isValid(value) {
    if ((typeof value === 'number' && !isNaN(value)) || value) {
      // 0 evaluates to false, but it is acceptable value for us
      return true;
    }
    return false;
  }

  #delimiter() {
    if (this.#isFirst) {
      this.#isFirst = false;
      return '?';
    }
    return '&';
  }

  #encode(value) {
    return encodeURIComponent(value);
  }

  #append(addition) {
    const newTarget = this.#target + this.#delimiter() + addition;
    if (newTarget.length < this.#maxLength) {
      this.#target = newTarget;
    }
  }

  /**
   * Add query parameter to the URL.
   * @param {String} key
   * @param {String} value
   * @return {HitSender} Current instance is returned to allow method chaining.
   */
  appendValue(key, value) {
    if (this.#isValid(value)) {
      this.#append(this.#encode(key) + '=' + this.#encode(value));
    }
    return this;
  }

  /**
   * Add query parameter to the URL, where value is an object.
   * @param {String} key
   * @param {Object} value
   * @return {HitSender} Current instance is returned to allow method chaining.
   */
  appendObject(key, obj) {
    if (obj) {
      this.#append(this.#encode(key) + '=' + this.#encode(JSON.stringify(obj)));
    }
    return this;
  }

  /**
   * Add query parameter to the URL, where value is an array.
   * @param {String} key
   * @param {Array} value
   * @return {HitSender} Current instance is returned to allow method chaining.
   */
  appendArray(key, arr) {
    if (Array.isArray(arr)) {
      arr.forEach((value) => this.appendValue(key, value));
    }
    return this;
  }

  /**
   * Send GET request to the target.
   */
  send() {
    this.#debug(`Sending hit to this address: ${this.#target}`);
    const pixel = new Image();
    pixel.src = this.#target;
  }
}
