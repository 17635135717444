import { getRetargetingUrl } from './utils/getRetargetingUrl';
import { getRetargetingData } from './utils/getRetargetingData';
import { rcDebug } from './debug';

import { RETARGETING_BASE_URL, COOKIE_NAMES } from './constants';
import {
  setConsent,
  getConsent,
  getVerifiedConsentValue,
  isWaitingForLegalStoreCheck,
  finishWaitingForLegalStoreCheck,
} from './consentManager';
import { getIdentityObject } from './utils/getIdentityObject';
import { HitSender } from './HitSender/HitSender';
import { handleUdid } from './udid';
import IS from '@iva/identity-storage';
import { SID_UTILS, COOKIE } from '@iva/utils';
import { SID_STORE_CHECK } from '@sklik/cmp2-common';

// retargetingHit queue
const queue = [];

/**
 * Adds retargetingHit call to queue
 */
export const addRetargetingHitToQueue = (func, conf) => {
  queue.push({ func, conf });
};

/**
 * Processes all calls in queue
 */
export const processRetargetingHitQueue = () => {
  if (queue.length > 0) {
    rcDebug({ message: `Starts processing queue containing ${queue.length} RetargetingHit(s):` });
  }

  while (queue.length > 0) {
    const { func, conf } = queue.shift();
    if (conf !== null && typeof conf === 'object' && getConsent() === 1) {
      // update consent when purposeOne is present
      conf.consent = 1;
    }
    func(conf, rcDebug);
  }
};

/**
 * Returns queue
 */
export const getRetargetingHitQueue = () => queue;

/**
 * Sends retargeting hit with provided data.
 * @param {Object} conf Data for the retargeting.
 * @param {Number} conf.rtgId Retargeting ID, Example = 123
 * @param {String} [conf.category] Eshop categories, Example="Zahrada | Stínící technika | Zahradní slunečníky"
 * @param {String} [conf.itemId]  Offer ID, Example = "shovel"
 * @param {String} [conf.pageType] Page type, Example = "eshop"
 * @param {String} [conf.rtgUrl] Current URL (must match window.location.href)
 * @param {String | String[]} [conf.productIds] Products ID, Uses internal services (partners do not send)
 * @param {Number} [conf.consent] Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
 * @param {Number} [conf.said] Cookie rus id. Uses internal services (partners do not send). There is no way to set it in window, it can be passed only when calling the function retargetingHit
 * @param {String} [conf.eid] hashed email address
 *
 * @param {Function} debug Debugger function from the script calling this function.
 * @return {Boolean} Indicator (true - retargetingHit sent , false - retargetingHit not sent)
 */

export const retargetingHit = (conf, debug = rcDebug) => {
  if (isWaitingForLegalStoreCheck()) {
    addRetargetingHitToQueue(window.rc.retargetingHit, conf);
    debug({ message: 'RetargetingHit is queued, consent verification not done yet.' });
    return false;
  }

  if (!conf || !conf.rtgId) {
    debug('error', 'RetargetingHit has not been sent. No required fields "conf" or "conf.rtgId".');
    return false;
  }

  debug({ message: 'Preparing RetargetingHit...' });

  const { rtgId, category, itemId, pageType, rtgUrl, productIds, consent, said, eid } = conf;

  const verifiedConsent = getVerifiedConsentValue(consent);

  setConsent(consent);
  handleUdid(rtgId, verifiedConsent);

  const { internal } = window.rc || {};
  const legallyOk = verifiedConsent || internal;
  const euconsentV2 = internal && COOKIE.getCookie(COOKIE_NAMES.EUCONSENT);
  const sidCookie = COOKIE.getCookie(COOKIE_NAMES.SID);
  const sid = legallyOk && SID_UTILS.isValidSid(sidCookie) ? sidCookie : '';
  const sznaiidCookie = COOKIE.getCookie(COOKIE_NAMES.SZNAIID);
  const sznaiid = legallyOk ? sznaiidCookie : '';
  if (eid) {
    // adding email eid to IdentityObject
    IS.updateIdentities({ eid });
  }
  const ids = getIdentityObject();

  debug({
    message: 'got following retargeting data:',
    rtgId,
    category,
    itemId,
    pageType,
    rtgUrl,
    productIds,
    euconsentV2,
    sid,
    sznaiid,
    consent,
    said,
    ids,
  });

  const consentValue = internal ? null : verifiedConsent;

  const getURL = () => {
    debug({ message: 'Generating retargeting URL', internal, category, rtgUrl });
    const finalRetURL = getRetargetingUrl(rtgUrl, category, internal);
    debug({ message: 'Final retargeting URL', finalRetURL });
    return finalRetURL;
  };

  new HitSender(RETARGETING_BASE_URL, debug)
    .appendValue('id', rtgId)
    .appendValue('category', category)
    .appendValue('itemId', itemId)
    .appendValue('url', getURL())
    .appendValue('pageType', pageType)
    .appendValue('euconsent', euconsentV2)
    .appendValue('dsid', sid)
    .appendValue('sznaiid', sznaiid)
    .appendArray('productIds', productIds)
    .appendValue('consent', consentValue)
    .appendValue('said', said)
    .appendObject('ids', ids)
    .send();

  debug({ message: 'RetargetingHit has been sent.' });
  return true;
};

/**
 * Initialize sending retargeting hits
 */
export const handleRetargetingHits = () => {
  rcDebug({ message: 'Retargeting Hits initialization started, verifying consent' });
  SID_STORE_CHECK.sidLegalStoreCheckPromise(5000)
    .then((allowed) => {
      finishWaitingForLegalStoreCheck();

      if (allowed) {
        // consent with purposeOne is present
        rcDebug({ message: 'Found consent with purposeOne, setting window.rc.consent = 1' });
        setConsent(1);
      }
    })
    .catch(() => {
      rcDebug({ message: 'Legal store check (consent) failed.' });
    })
    .finally(() => {
      rcDebug({ message: 'Retargeting consent verification done.' });
      // retargetingHit
      const retargetingConf = getRetargetingData();
      rcDebug({ message: 'Retargeting got this data:', ...retargetingConf });
      rcDebug({ message: 'Tries to send initial RetargetingHit:' });
      if (retargetingHit(retargetingConf, rcDebug)) {
        window.seznam_dispatchedRetargetingIds = window.seznam_dispatchedRetargetingIds || [];
        window.seznam_dispatchedRetargetingIds.push(retargetingConf.rtgId);
      }

      // process all window.rc.retargetingHit calls
      processRetargetingHitQueue();
    });
};
