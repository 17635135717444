import { getConsent } from '../consentManager';

/**
 * Gets retargeting ID from the window object
 * @return {String | Number | null}  retargeting ID
 */
export const getRtgId = () => {
  return window.seznam_retargetingId || window.seznam_retargeting_id || null;
};

/**
 * Retrieves data from the window object for retargetingHit
 * @return {Object | null} conf for retargetingHit
 */
export const getRetargetingData = () => {
  const rtgId = getRtgId();

  if (!rtgId || (window.seznam_dispatchedRetargetingIds && ~window.seznam_dispatchedRetargetingIds.indexOf(rtgId))) {
    return null;
  }

  const data = {
    rtgId, /// * {Number} Retargeting ID, Required field, Example = 123*/
    category: window.seznam_category, // {String} Eshop categories, Example="Zahrada | Stínící technika | Zahradní slunečníky"
    itemId: window.seznam_itemId, // {String} Offer ID, Example = "shovel"
    pageType: window.seznam_pagetype, // {String} Page type, Example = "eshop"
    rtgUrl: window.seznam_rtgUrl, // {String} Current URL (must match window.location.href)
    productIds: window.seznam_productIds, // {Array<String|Number>} Products ID, used only by internal services (partners do not fill in)
    consent: getConsent(), // {Number} Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
  };

  return data;
};
