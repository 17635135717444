import { getConsent } from '../consentManager';

/**
 * Retrieves data from the window object for conversionHit, which will only send to SKLIK server
 * @return {Object | Array<Object> | null} conf for conversionHit
 */
export const getConversionDataSklik = () => {
  const cId = window.seznam_cId; // {Number | Array<Number>} Conversion ID, Required field (if no zboziId), Example = 123
  const zboziId = window.seznam_zboziId; // * {Number} Eshop ID, Required field (if no id), Example = 123
  if (!cId && !zboziId) {
    return null;
  }
  const data = {
    value: window.seznam_value, // {Number} Order price, Example = 199
    orderId: window.seznam_orderId, // {String} OrderId, Example = 'DN1231882C'
    zboziId,
    zboziType: window.seznam_zboziType, // {String} Zbozi testing mode = 'sandbox'
    consent: getConsent(), // Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
  };

  if (Array.isArray(cId)) {
    const arrayConversionConf = [];
    cId.forEach((id, index) => {
      const currentZboziId = index ? null : zboziId; // send zboziId only once
      arrayConversionConf.push({ id, ...data, zboziId: currentZboziId });
    });
    return arrayConversionConf.length > 0 ? arrayConversionConf : null;
  } else {
    return { id: cId, ...data };
  }
};

/**
 * Retrieves data from the window object for conversionHit, which will only send to ZBOZI server
 * @return {Object | null} conf for conversionHit
 */
export const getConversionDataZbozi = () => {
  const zboziId = window.seznam_zboziId;
  const orderId = window.seznam_orderId;
  if (!zboziId || !orderId) {
    return null;
  }
  const data = {
    zboziId, // * {Number} Eshop ID, Required field, Example = 123
    value: window.seznam_value, // {Number} Order price, Example = 199
    orderId, // {String} OrderId, Example = 'DN1231882C'
    zboziType: window.seznam_zboziType, // {String} Type of zbozi message, Example = 'standard' (standard conversion tracking), 'limited' (limited tracking), 'sandbox' (standard tracking test mode)
    consent: getConsent(), // {Number} Consent from the visitor to submit a retargeting hit, Example = 0 (není souhlas) nebo 1 (je souhlas)
  };
  return data;
};
