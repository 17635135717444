// Modules
import { rcDebug as debug } from './debug.js';

// Constants
import { ZBOZI_BASE_URL } from './constants';

const createZboziConversionFunction = (shopId) => {
  window.ZboziConversionObject = 'zbozi';

  //NOTE: This will override all previous zbozi scripts!
  window.zbozi = function () {
    debug({ message: 'window.zbozi called with', ...arguments });
    (window.zbozi.q = window.zbozi.q || []).push(arguments);
    window.zbozi.key = shopId;
  };
};

const attachZboziConversionScript = (version) => {
  const scriptElement = document.createElement('script');

  scriptElement.async = true;

  const url = `${ZBOZI_BASE_URL}${version === 'v3' ? '-v3' : ''}.js`;

  scriptElement.src = url;

  const [firstScriptElement] = document.getElementsByTagName('script');

  if (firstScriptElement) {
    firstScriptElement.parentNode.insertBefore(scriptElement, firstScriptElement);
  }
};

const processZboziConversion = (conf) => {
  if (!conf?.zboziId || !conf?.orderId || !conf?.conversionHitId) {
    debug(
      'error',
      'ConverionHit has not been sent to zbozi server. No required fields "conf.zboziId or "conf.orderId" or "conf.conversionHitId".'
    );
    return;
  }
  const { value, orderId, zboziType, zboziId, consent, conversionHitId } = conf;

  debug({ message: 'processZboziConversion with', zboziType, zboziId, orderId, consent, conversionHitId });

  if (zboziType === 'standard' || zboziType === 'sandbox' || !zboziType) {
    createZboziConversionFunction(zboziId);

    if (zboziType === 'sandbox') {
      window.zbozi('useSandbox');
    }

    attachZboziConversionScript('v3');
  }

  if (zboziType === 'limited') {
    createZboziConversionFunction(zboziId);
    attachZboziConversionScript();
  }

  // Depends on previous conditions
  if (window.zbozi) {
    const orderData = { orderId };
    if (consent === 1 || consent === 0) {
      orderData.consent = consent;
    }

    if (zboziType === 'limited' && value) {
      orderData.totalPrice = value;
    }
    orderData.conversionHitId = conversionHitId;

    window.zbozi('setOrder', orderData);
    window.zbozi('send');
  }
};

export const conversionHitZbozi = (conf) => {
  /*
   * This is little hack for pushing zbozi conversion script at the end of process,
   * because if there is more than 1 zbozi conversion script, they will synchronously rewrite own keys on
   * window and queue will not work properly.
   */
  if (!window.zbozi) {
    // In this case, there is only this zbozi conversion script
    processZboziConversion(conf);
  } else {
    const intervalId = setInterval(() => {
      /*
       * In this case is another script on the page (but not this one) and we need to check
       * if window.zbozi is already overwritten by function in first script.
       */
      if (!window.zbozi.q) {
        processZboziConversion(conf);
        clearInterval(intervalId);
      }
    }, 100);
  }
};
