import 'core-js/es/object/assign';
import 'custom-event-polyfill';

import { debugFactory } from '@iva/debugger';

const rcConfig = {
  name: 'rc',
  bColor: 'gold',
  fColor: 'black',
};

const retargetingConfig = {
  name: 'retargeting',
  bColor: 'yellow',
  fColor: 'grey',
};

const rcDebug = debugFactory(rcConfig);
const retargetingDebug = debugFactory(retargetingConfig);

export { retargetingDebug, rcDebug };
